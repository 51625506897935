import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import {
  Tldraw,
  useEditor,
  MediaHelpers,
  AssetRecordType,
  createShapeId,
  SVGContainer,
  ShapeUtil,
  Geometry2d,
  HTMLContainer,
  RecordProps,
  Rectangle2d,
  T,
  TLBaseShape,
  TLOnResizeHandler,
  resizeBox,
  ImageShapeUtil,
  Vec,
toFixed,
} from "tldraw";
import { InteractiveObjectTool } from "./InteractiveObjectTool";
import { InteractiveObjectUtil } from "./InteractiveObjectUtil";
import { components, customAssetUrls, uiOverrides } from "./ui-overrides";
import "./customhandles.css";
import { useStore, StoreTypes } from "context";
import { groupPages, preserveSVGAspectRatio} from "util/book";
import styles from "../BookPage/index.module.scss";
import classnames from 'classnames';
import { useFetchBooks } from "customHooks/book";


// function useTick(isEnabled = true) {
// 	const [_, setTick] = useState(0)
// 	const editor = useEditor()
// 	useEffect(() => {
// 		if (!isEnabled) return
// 		const update = () => setTick((tick) => tick + 1)
// 		editor.on('tick', update)
// 		return () => {
// 			editor.off('tick', update)
// 		}
// 	}, [editor, isEnabled])
// }

let oldWidth;
let oldHeight;
const transFormSVG = ({svg, width, height, viewWidth, viewHeight}) => {
    svg = svg.replace(`width="${width}"`, `width="${viewWidth}"`);
    svg = svg.replace(`height="${height}"`, `height="${viewHeight}"`);
    svg = svg.replace('<svg', '<svg preserveAspectRatio="none"');
    return svg;
}
const transFormInteractiveObjectSVG = ({svg, viewWidth, viewHeight}) => {
    svg = svg.replace(`width="100%"`, `width="${viewWidth}"`);
    svg = svg.replace(`height="100%"`, `height="${viewHeight}"`);
    svg = svg.replace('<svg', '<svg preserveAspectRatio="none"');
    return svg;
}
class MyShapeUtil extends ShapeUtil {
  static type = "my-custom-shape";
  static props = {
    w: T.number,
    h: T.number,
    svg: T.string,
  };

  getDefaultProps() {
    return {
      w: 500,
      h: 500,
      svg: ''
    };
  }

  canEdit = () => false;
  canResize = () => false;
  isAspectRatioLocked = () => false;

  getGeometry(shape) {
    return new Rectangle2d({
      width: shape.props.w,
      height: shape.props.h,
      isFilled: true,
    });
  }

  onResize = (shape, info) => {
    return resizeBox(shape, info);
  };

  component(shape) {
    // const [interactiveObject, setInteractiveObject] = useState([]);
    const ref = useRef();

    // useEffect(() => {
    //   (async () => {
    //     const tempInteractiveObject = [];
    //     const interactiveObjectLeft = await fetch(objectUrlLeft)
    //       .then((res) => res.json())
    //       .then((data) => data.rawData.svg);
    //     tempInteractiveObject.push(interactiveObjectLeft);
    //     // const blobInteractiveObjectLeft = new Blob([interactiveObjectLeft], {type: 'image/svg+xml'})
    //     // const { w:wIL, h:hIL } = await MediaHelpers.getImageSize(blobInteractiveObjectLeft)
    //     // tempInteractiveObject.push({src: URL.createObjectURL(blobInteractiveObjectLeft), width: wIL, height: hIL, type: blobInteractiveObjectLeft.type})
    //     const interactiveObjectRight = await fetch(objectUrlRight)
    //       .then((res) => res.json())
    //       .then((data) => data.rawData.svg);
    //     tempInteractiveObject.push(interactiveObjectRight);
    //     // const blobInteractiveObjectRight = new Blob([interactiveObjectRight], {type: 'image/svg+xml'})
    //     // const { w:wIR, h:hIR } = await MediaHelpers.getImageSize(blobInteractiveObjectRight)
    //     // tempInteractiveObject.push({src: URL.createObjectURL(blobInteractiveObjectRight), width: wIR, height: hIR, type: blobInteractiveObjectRight.type})

    //     setInteractiveObject(tempInteractiveObject);
    //   })();
    // }, []);
    const handleClick = (e) => {
        e.stopPropagation();
        alert('點擊互動物件');
    }

    useEffect(() => {
      //   const node = ReactDOM.findDOMNode(ref.current);
      ref.current.querySelectorAll("g").forEach((group) => {
        group.style.cursor = `pointer`;
        group.addEventListener('click',handleClick)
      });

      return () => {
        ref.current.querySelectorAll("g").forEach((group) => {
            group.style.cursor = `pointer`;
            group.removeEventListener('click',handleClick)
          });
      }

    }, [ref, shape.props.svg, shape.props.w, shape.props.h]);

    return (
    //   <HTMLContainer
    //     id={shape.id}
    //     style={{
    //       pointerEvents: "all",
    //         height: "100%",
    //         width: "100%",
    //     }}
    //   >
        <div
        style={{pointerEvents: "all"}}
          dangerouslySetInnerHTML={{ __html: transFormInteractiveObjectSVG({svg: shape.props.svg, viewWidth: shape.props.w, viewHeight: shape.props.h}) }}
          ref={ref}
        ></div>
    //   </HTMLContainer>
    );
  }
}

class MyImage extends ShapeUtil {
  static type = "my-image";
  static props = {
    w: T.number,
    h: T.number,
    svg: T.string,
    bookInfo: T.any,
    pageIndex: T.number,
    isDoublePageMode: T.boolean,
  };
  getDefaultProps() {
    return {
      w: 500,
      h: 500,
      svg: "",
      bookInfo: {},
      pageIndex: 0,
      isDoublePageMode: true,
    };
  }
  canEdit = () => false;
  canResize = () => false;
  isAspectRatioLocked = () => false;
  getGeometry(shape) {
    return new Rectangle2d({
      width: shape.props.w,
      height: shape.props.h,
      isFilled: true,
    });
  };
  component(shape) {
    return (
        <div
        style={{height: '100%', width: '100%'}} 
        // className={classnames(styles.svgWrapper, {
        //     [styles.switch]: SVGSwitch
        //   })}
          >
            <div
              id='svgContent'
            //   className={classnames(styles.svgContent, {
            //     [styles.disableSelection]: !isMarkModeShow
            //   })}
              dangerouslySetInnerHTML={{
                __html: transFormSVG({
                  svg: shape.props.svg,
                  ...shape.props.bookInfo,
                 viewHeight: shape.props.h,
                 viewWidth: shape.props.w
                })
                // __html: shape.props.svg
              }}
            />
          </div>

    );
  }
}


// const urlLeft =
//   "https://cdn.oneclass.com.tw/uploadoutput/47607f41562492b3/1.svg";
// const urlRight =
//   "https://cdn.oneclass.com.tw/uploadoutput/47607f41562492b3/2.svg";
// const objectUrlLeft =
//   "https://onebook-dev.oneclass.com.tw/interactiveObjects/116c25faa7fb4b4f94a5c59b0e5c0f20/1.dat";
// const objectUrlRight =
//   "https://onebook-dev.oneclass.com.tw/interactiveObjects/116c25faa7fb4b4f94a5c59b0e5c0f20/2.dat";

const customShape = [MyShapeUtil, MyImage];

const TldrawContainer = ({ book }) => {
    const [{ bookContent, interactiveObjectSVG, interactiveObjectJSON }] =
      useStore(StoreTypes.books);
    const [{ isDoublePageMode, isMarkModeShow, pageIndex }] = useStore(
      StoreTypes.reader
    );
    const { fetchBook } = useFetchBooks();
    const [
      {
        fullWidthInfo: { mode: fullWidthMode },
      },
    ] = useStore(StoreTypes.reader);
    const { bookId, pageInfos, LRFlip } = book;


//   const [book, setBook] = useState([]);
  const [bookData, setBookData] = useState({});
  const [editor, setEditor] = useState();
  const [imageShapeId, setImageShapeId] = useState([]);
  const [interactiveObjectId, setInteractiveObjectId] = useState([]);
  const readerView = document.querySelector("#readerView");
//   const width = fullWidthMode
//     ? readerView.clientWidth
//     : readerView.clientWidth * (book.width / book.height);
//   const height = readerView.clientHeight

  let width = 500;
  let height = 500;
  if (fullWidthMode) {
    width = readerView?.clientWidth;
    height = readerView?.clientHeight;
  } else {
    // TODO: 符合書本比例時，該以長為基準，還是寬為基準，以及對應長寬的計算，需要重新思考。尤其是螢幕超大，高 3000px ，而書高（book.height）是 1000px 的情況。
    if (book.height > readerView?.clientHeight && readerView?.clientHeight * ((book.width * 2) / book.height)< readerView?.clientWidth) {
        width = readerView?.clientHeight * ((book.width * 2) / book.height);
        height = readerView?.clientHeight;
    }else{
        width = readerView?.clientWidth;
        height = readerView?.clientWidth * (book.height / (book.width * 2)); 
    }
  }

    useEffect(() => {
        const data = groupPages(bookContent, isDoublePageMode);
        setBookData(data);
      }, [LRFlip, bookContent, isDoublePageMode]);

//   useEffect(() => {
//     (async () => {
//       const temp = [];
//       const imageLeft = await fetch(urlLeft);
//       const blobLeft = await imageLeft.blob();
//       const { w: wL, h: hL } = await MediaHelpers.getImageSize(blobLeft);
//       temp.push({ src: urlLeft, width: wL, height: hL, type: blobLeft.type });
//       const imageRight = await fetch(urlRight);
//       const blobRight = await imageRight.blob();
//       const { w: wR, h: hR } = await MediaHelpers.getImageSize(blobRight);
//       temp.push({ src: urlRight, width: wR, height: hR, type: blobRight.type });

//       setBook(temp);
//     })();
//   }, []);

  useEffect(() => {
    if (!editor || imageShapeId.length === 2) return;
    const page = bookData[pageIndex];
    let pages = [];
      const threshold = 3;
      if (isDoublePageMode) {
        let startPage = Math.max(0, pageIndex * 2 - threshold * 2);
        let endPage = Math.min(
          pageIndex * 2 + 1 + threshold * 2,
          pageInfos.length - 1
        );
        for (let i = startPage; i <= endPage; ++i) {
          pages.push(i);
        }
      } else {
        let startPage = Math.max(0, pageIndex - threshold);
        let endPage = Math.min(pageIndex + threshold, pageInfos.length - 1);
        for (let i = startPage; i <= endPage; ++i) {
          pages.push(i);
        }
      }
      pages = pages.filter(page => !bookContent[page]);
      if (pages.length > 0) {
        fetchBook({ bookId, pages });
      }
      page && page.forEach((page, index) => {
        // const assetId = AssetRecordType.createId();
        // editor.createAssets([
        //   {
        //     id: assetId,
        //     typeName: "asset",
        //     type: "image",
        //     meta: {},
        //     props: {
        //       w: page.width,
        //       h: page.height,
        //       mimeType: page.type,
        //       src: page.src,
        //       name: "image",
        //       isAnimated: false,
        //     },
        //   },
        // ]);
        const shapeId = createShapeId();
        editor.createShape({
          id: shapeId,
          type: "my-image",
          x: index ? width / 2 : 0,
          y: 0,
          isLocked: true,
          props: {
            w: width / 2,
            h: height,
            svg: page.svg,
            bookInfo: book,
            pageIndex,
            isDoublePageMode
          },
        });
        editor.sendToBack([shapeId]);
        setImageShapeId((prev) => {
          const temp = [...prev];
          temp.push(shapeId);
          return temp;
        });
        const interactiveObjectId = createShapeId();
        editor.createShape({
            id: interactiveObjectId,
            type: "my-custom-shape",
            x: index ? width / 2 : 0,
            y: 0,
            isLocked: true,
            props: {
                svg: interactiveObjectSVG[page.pageIndex],
                w: width /2,
                h: height,
            }
        })
        setInteractiveObjectId((prev) => {
            const temp = [...prev];
            temp.push(interactiveObjectId);
            return temp;
          });
      });
    }
  , [bookData, editor, imageShapeId, pageIndex, isDoublePageMode]);

  //     useEffect(() => {
  //         if (!editor) return
  //         	// Make sure the shape is at the bottom of the page
  // 		function makeSureShapeIsAtBottom(shapeId) {
  // 			if (!editor) return

  // 			const shape = editor.getShape(shapeId)
  // 			if (!shape) return

  // 			const pageId = editor.getCurrentPageId()

  // 			// The shape should always be the child of the current page
  // 			if (shape.parentId !== pageId) {
  // 				editor.moveShapesToPage([shape], pageId)
  // 			}

  // 			// The shape should always be at the bottom of the page's children
  // 			const siblings = editor.getSortedChildIdsForParent(pageId)
  // 			const currentBottomShape = editor.getShape(siblings[0])
  // 			if (currentBottomShape.id !== shapeId) {
  // 				editor.sendToBack([shape])
  // 			}
  // 		}
  // imageShapeId.forEach(shapeId => {
  // 		makeSureShapeIsAtBottom(shapeId)
  // })

  // 		const removeOnCreate = editor.sideEffects.registerAfterCreateHandler(
  // 			'shape',
  // 			() => {
  //                 imageShapeId.forEach(makeSureShapeIsAtBottom)
  //             }
  // 		)

  // 		const removeOnChange = editor.sideEffects.registerAfterChangeHandler(
  // 			'shape',
  // 			() => {
  //                 imageShapeId.forEach(makeSureShapeIsAtBottom)
  //             }
  // 		)

  // 		// The shape should always be locked
  // 		// const cleanupKeepShapeLocked = editor.sideEffects.registerBeforeChangeHandler(
  // 		// 	'shape',
  // 		// 	(prev, next) => {
  //         //         imageShapeId.forEach(shapeId => {
  // 		// 		if (next.id !== shapeId) return next
  // 		// 		if (next.isLocked) return next
  // 		// 		return { ...prev, isLocked: true }
  //         //         })
  // 		// 	}
  // 		// )

  //         return () => {
  //             removeOnChange()
  // 			removeOnCreate()
  // 			// cleanupKeepShapeLocked()
  //         }
  //     }, [imageShapeId, editor])

  // useEffect(() => {
  //     if (!editor) return

  //      if (interactiveObject.length === 2) {
  //         interactiveObject.forEach((page, index) => {
  //             const assetId = AssetRecordType.createId()
  //             editor.createAssets([
  //                 {
  //                     id: assetId,
  //                     typeName: 'asset',
  //                     type: 'image',
  //                     meta: {},
  //                     props: {
  //                         w: page.width,
  //                         h: 200,
  //                         mimeType: page.type,
  //                         src: page.src,
  //                         name: 'image',
  //                         isAnimated: false,
  //                     },
  //                 },
  //             ])
  //             const shapeId = createShapeId()
  //             editor.createShape({
  //                 id: shapeId,
  //                 type: 'image',
  //                 x: index ? (document.querySelector('#tlContainer').clientWidth/2)/0.61 :0,
  //                 y: 0,
  //                 isLocked: true,
  //                 props: {
  //                     w: page.width,
  //                     h: page.height,
  //                     assetId,
  //                 },
  //             })
  //             setImageShapeId(prev => {
  //                 const temp = [...prev]
  //                 temp.push(shapeId)
  //                 return temp
  //             })
  //         })
  //     }
  // }, [interactiveObject, editor])

  useEffect(() => {
    if (!editor) return;

    const resizeCamera = () => {
        if(!oldWidth)oldWidth = width;
        if(!oldHeight)oldHeight = height;
      const shapes = editor.getCurrentPageShapes();
      const scaleX = width / oldWidth;
      const scaleY = height / oldHeight;


      shapes.forEach(shape => {
        if (shape.type === "draw") {
            const newSegments = [];
            for (const segment of shape.props.segments) {
                newSegments.push({
                    ...segment,
                    points: segment.points.map(({ x, y, z }) => {
                        return {
                            x: toFixed(scaleX * x),
                            y: toFixed(scaleY * y),
                            z,
                        }
                    }),
                })
            }
            editor.updateShape({ id: shape.id ,props:{segments: newSegments},x: shape.x * (scaleX), y: shape.y * (scaleY)});
        }

        if (shape.type === "my-image") {
            console.log('resizeCamera', width, height)
          imageShapeId[0] &&
            editor.updateShape({
              id: imageShapeId[0],
              props: { w: width / 2, h: height },
              x: 0,
              y: 0,
              isLocked: true,
            });
          imageShapeId[1] &&
            editor.updateShape({
              id: imageShapeId[1],
              props: { w: width / 2, h: height },
              x: width / 2,
              y: 0,
              isLocked: true,
            });
        }

        if (shape.type === "my-custom-shape") {
          interactiveObjectId[0] &&
            editor.updateShape({
              id: interactiveObjectId[0],
              props: { w: width / 2, h: height },
              x: 0,
              y: 0,
              isLocked: true,
            });
          interactiveObjectId[1] &&
            editor.updateShape({
              id: interactiveObjectId[1],
              props: { w: width / 2, h: height },
              x: width / 2,
              y: 0,
              isLocked: true,
            });
        }

      })
      oldHeight = height;
      oldWidth = width;
    };
    window.addEventListener("resize", () => resizeCamera());

    editor.setCameraOptions({
      constraints: {
        initialZoom: "fit-max",
        baseZoom: "fit-max",
        bounds: { w: width, h: height, x: 0, y: 0 },
        padding: { x: 0, y: 0 },
        origin: { x: 0, y: 0 },
        behavior: "contain",
      },
      zoomSteps: [1, 2, 4, 8],
      zoomSpeed: 1,
      panSpeed: 1,
      isLocked: false,
    });
    editor.setCamera(editor.getCamera(), { reset: true });
    resizeCamera();

    //   if(imageShapeId.length === 2) {
    //     imageShapeId.forEach((id, index) => {
    //         const shape = editor.getShape(id)
    //         if (!shape) return
    //         document.getElementById(id).querySelector('.tl-image').style.transform = `scaleX(1.2) scaleY(1)`;
    //         document.getElementById(id).querySelector('.tl-image').style.transformOrigin = `top left`

    //       });
    //   }

    return () => {
      window.removeEventListener("resize", resizeCamera);
    };
  }, [editor, width, height, imageShapeId]);

  return (
    <div
      style={{ position: "fixed", width: width, height: height, inset: 0, outline: "10px solid red", margin: "0 auto"}}
      id="tlContainer"
    >
      <Tldraw
        shapeUtils={customShape}
        // tools={tools}
        // overrides={uiOverrides}
        // assetUrls={customAssetUrls}
        components={{
          DebugPanel: false,
        //   ZoomMenu: false,
          PageMenu: false,
        }}
        onMount={(editor) => {
          // editor.setCameraOptions({isLocked: true});
          editor.setCurrentTool("draw");
        //   editor.createShape({
        //     type: "my-custom-shape",
        //     x: 0,
        //     y: 0,
        //     isLocked: true,
        //   });
          setEditor(editor);
        }}
      >
      </Tldraw>
    </div>
  );
};

export default TldrawContainer;
